// Main imports
import { Routes, Route, Navigate } from "react-router-dom";

// Views and components
import Home from "./views/Home/Home";
import Locations from "./views/Locations/Locations";
import Navbar from "./components/ui/navbar/Navbar";

// Data and context
import WebplaceProvider from "./store/webplace-provider";

// Utilities
import "./App.css";


const App = () => {
  return (
    <WebplaceProvider>
      <Navbar />
      <div className="spacer"></div>

      <Routes>
        <Route path="/" element={<Home />} />

        {/* Show and redirect to Locations */}
        <Route
          path="/locations"
          element={<Locations />}
        />


        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </WebplaceProvider>
  );
};

export default App;
