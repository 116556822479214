import { useContext, useEffect, useState } from "react";
import ButtonFormat from "../../shared/ButtonFormat/ButtonFormat";
import WebplaceContext from "../../../store/webplace-context";

import "./HeroImage.css";
import favicon from "../../../assets/mainItems/faviconHero.svg"

const HeroImage = () => {
  const placeCtx = useContext(WebplaceContext).homeBanner;
  const stylesCtx = useContext(WebplaceContext).homeBanner.home_banner_styles;

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const orderBtns = placeCtx.banner_items.map((data, index) => {
    return (
      <div className="heroimg-buttons" key={`${index}orderButton`}>


        <ButtonFormat
          btnSize={stylesCtx.options_btns.btn_size}
          showArrow={stylesCtx.options_btns.show_arrow}
          arrowAtStart={stylesCtx.options_btns.arrow_at_start}
          arrowColor={
            windowSize >= 778
              ? stylesCtx.options_btns.arrow_color
              : stylesCtx.options_btns_mobile.arrow_color
          }
          showBorder={stylesCtx.options_btns.show_border}
          borderColor={stylesCtx.options_btns.border_color}
          backgroundColor={stylesCtx.options_btns.background_color}
          fontColor={
            windowSize >= 778
              ? stylesCtx.options_btns.font_color
              : stylesCtx.options_btns_mobile.font_color
          }
          capitalizeText={stylesCtx.options_btns.capitalize_text}
          btnText={data.item}
          url={placeCtx.banner_items[index].url}
        />
      </div>
    );
  });

  return (
    <div>
      <div className="hero-img-wrapper">
        <img src={favicon} alt="Margaritaville Resort Orlando" className="hero-img-title-img" />

        <div className="hero-img-content">
          <div className="btn-wrapper">
            <ButtonFormat
              btnSize={windowSize > 1400 ? 2 : 1.4}
              showArrow={stylesCtx.banner_btn.show_arrow}
              arrowAtStart={stylesCtx.banner_btn.arrow_at_start}
              arrowColor={stylesCtx.banner_btn.arrow_color}
              showBorder={stylesCtx.banner_btn.show_border}
              borderColor={stylesCtx.banner_btn.border_color}
              backgroundColor={stylesCtx.banner_btn.background_color}
              fontColor={stylesCtx.banner_btn.font_color}
              capitalizeText={stylesCtx.banner_btn.capitalize_text}
              btnText={placeCtx.button_text}
              url={placeCtx.url}
            />
          </div>
        </div>
        {windowSize > 778 && <div className="options-wrapper">{orderBtns}</div>}
      </div>
      {windowSize <= 778 && (
        <div className="mobile-options-wrapper">
          <div className="mobile-options">{orderBtns}</div>
        </div>
      )}
    </div>
  );
};

export default HeroImage;
