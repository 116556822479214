import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";

// import favicon from "../../../assets/mainItems/favicon.svg";
// import ButtonFormat from "../../shared/ButtonFormat/ButtonFormat";
import "./Navbar.css";
import WebplaceContext from "../../../store/webplace-context";

import thePaul from "../../../assets/mainItems/thePaul.png"
import TRYP from "../../../assets/mainItems/TRYP.png"
import econoLodge from "../../../assets/mainItems/econoLodge.jpg"


const Navbar = () => {
  const navbarCtx = useContext(WebplaceContext).navbarItems;
  const stylesCtx = useContext(WebplaceContext).navbarItems.navbar_styles;
  let logoSize;

  const [hamburgerStatus, setHamburgerStatus] = useState(false);
  // Open and close hamburger
  const mobileHamburgerHandler = () => {
    setHamburgerStatus(!hamburgerStatus);
  };

  // Close hamburger if element is clicked
  const mobileHamburgerOptionHandler = () => {
    if (hamburgerStatus) {
      setHamburgerStatus(false);
    }
  };

  if (navbarCtx.logo_size === "large") {
    logoSize = "nav-fav-large";
  } else if (navbarCtx.logo_size === "medium") {
    logoSize = "nav-fav-medium";
  } else {
    logoSize = "nav-fav-small";
  }

  const redirectionStatus = (url) => {
    let redirection;
    // eslint-disable-next-line
    if (url.startsWith("/")) {
      redirection = "_self";
    } else {
      redirection = "_self";
    }
    return redirection;
  };

  const menuItems = (
    <>
      {navbarCtx.options.map((navitem) => {
        return (
          <NavLink
            key={navitem.title}
            className={navitem.shown ? "menu-items" : "menu-disable"}
            style={{ color: stylesCtx.font_color }}
            to={navitem.url}
            target={redirectionStatus(navitem.url)}
            onClick={mobileHamburgerOptionHandler}
          >
            {navitem.shown && navitem.title}
          </NavLink>
        );
      })}

    </>
  );

  return (
    <div
      className="navbar-component-container"
      style={{ backgroundColor: stylesCtx.background_color }}
    >
      <div className="navbar-logo">
        <img
          src={TRYP}
          className={`navbar-fav-image ${logoSize}`}
          alt="TRYP Logo"
        />
        <img
          src={econoLodge}
          className={`navbar-fav-image ${logoSize}`}
          alt="EconoLodge Logo"
        />

        <img
          src={thePaul}
          className={`navbar-fav-image ${logoSize}`}
          alt="The Paul NYC Logo"
        />

      </div >
      <nav>
        <div className="nav-container">
          <div className="menu">{menuItems}</div>
          <button
            className={
              hamburgerStatus ? "hamburger active-hamburger" : "hamburger"
            }
            onClick={mobileHamburgerHandler}
          >
            <span style={{ backgroundColor: stylesCtx.font_color }}></span>
            <span style={{ backgroundColor: stylesCtx.font_color }}></span>
            <span style={{ backgroundColor: stylesCtx.font_color }}></span>
          </button>
        </div>
      </nav>
      <div
        className={`${hamburgerStatus ? "active-dropdown" : "inactive-dropdown"
          }`}
      >
        <div
          className="nav-dropdown"
          style={{ backgroundColor: stylesCtx.navbar_mobile_dropdown }}
        >
          {menuItems}
        </div>
      </div>
    </div >
  );
};

export default Navbar;
